.values-home {
    padding: 10rem 0;
    background-image: linear-gradient(to right, var(--color-primary-light), var(--color-primary-dark)), url(../../assets/crop.jpg);
    background-size: cover;
    background-position: top;
    transform: skewY(-6deg);
    margin-top: 8rem; 
    height: calc(100vh - 16rem); 
}

.values-home > * {
    transform: skewY(6deg); 
} 


.values-box {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    padding: 2rem;
    margin: 2.5rem;
    text-align: center;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: .2rem;
    transition: all .3s;
    margin-top: 2rem; 
}

.values-box:hover {
    transform: translateY(-1.5rem) scale(1.03); 
}


.values-box-icon {
    font-size: 5rem;
    /* margin-bottom: .5rem; */
    display: inline-block;
    background-image: linear-gradient(to right, var(--color-primary-light), var(--color-primary-dark));
    -webkit-background-clip: text;
    color: transparent; 
}

@media (max-width: 1120px) {
    .values-home {
        padding: 10rem 0;
        margin-top: 2rem; 
        /* height: calc(100vh - 16rem);  */
    }
    .values-box {
        font-size: 1.5rem;
        padding: 0.5rem;
        margin: 1.5rem;
    }
}