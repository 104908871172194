.small-nav-navbar{
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.small-nav-list{
    list-style: none;
    text-align: center;
    width: 38%;
}
.small-nav-item{
    margin: 1rem;
    position: relative;
}
.small-nav-link{
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 300;
    padding: 1rem 2rem;
    font-family: var(--font-3); 
    color: var(--color-grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-grey-light-1) 50%);
    background-size: 280%;
    transition: all .4s;
}
.small-nav-link:hover{
    background-position: 100%;
    color: var(--color-primary-light);
    transform: translateX(1rem);
}

.small-nav-button{
    background-color: var(--color-grey-light-1);
    height: 3rem;
    width: 3rem;
    position: fixed;
    top: 2%;
    right: 2%;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: var(--shadow-dark);
    text-align: center;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .small-nav-icon-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;

    height: 50%
  }
  .small-nav-icon{
    width: 2rem;
    height: 1px;
    background-color:var(--color-grey-dark-1);
  }

  .small-nav-background{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    position: fixed;
    top: 2%;
    right: 2%;
    background-image: radial-gradient( var(--color-primary-light), var(--color-primary-dark));
    z-index: 1000;
    transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
  }

.small-nav-background-open {
  z-index: 1500;
  transform: scale(80);
}


