.contact-home{
    margin-top: 8rem;
    height: calc(100vh - 20rem);
    position: relative;
    color: #fff;
}

.contact-holder{
    width: 60%;
    height: 90%;
    padding: 1rem;
    background-color: rgba(0,0,0,.7);
    color: #fff;
}

.header-contact{
    color: #fff !important;
}

.info-holder{
    /* height: 80%;
    display:flex;
    flex-direction: row; */

    padding: 2rem;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.contact-holder .image{
    height: 5rem;
}

@media (max-width: 1120px) {
    .info-holder{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
    .info-holder div{
        padding: 2rem;
    }
}
@media (max-width: 895px) {
   
}
@media (max-width: 786px) {
    
}