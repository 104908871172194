.qc-holder{
    height: 40rem
}
.col-images{
    position: relative;
}
.img-col {
    position: relative;
    width: 100%;
    height: 100%; 
}

.img-col .qc-img-composition {
    position: relative;
    width: 60%;
    height: 100%; 
}
.img-col .qc-img-composition [class^="qc-img-"] {
    width: 50%;
    height: 50%;
    position: absolute;
    border-radius: 0.2rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    transition: all 0.2s;
    outline-offset: 1rem; 
}
.img-col .qc-img-composition [class^="qc-img-"]:hover {
    transform: scale(1.1);
    z-index: 1;
    box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
    outline: 0.8rem solid var(--color-primary-light);
}
.img-col .qc-img-composition .qc-img-1 {
    top: 0;
    left: 0; 
}
.img-col .qc-img-composition .qc-img-2 {
    top: 4rem;
    left: 40%; 
}
.img-col .qc-img-composition .qc-img-3 {
    top: 15rem;
    left: 15%; 
}
.img-col .qc-img-composition:hover .qc-all-img:not(:hover) {
    transform: scale(0.95); 
}

.lab-heading {
    color: var(--color-grey-dark-1);
    text-transform: uppercase; 
}
  
.lab-paragraph {
    color: var(--color-grey-dark-2);
}

@media (max-width: 786px) {
    .img-col {
        width: 80%;
        height: 50%; 
    }
    .col-images{
        grid-row: 1/2
    }
    .img-col .qc-img-composition [class^="qc-img-"] {
        width: 40%;
        height: 70%;
    }
    .img-col .qc-img-composition .qc-img-1 {
        top: 0;
        left: 0; 
    }
    .img-col .qc-img-composition .qc-img-2 {
        top: 4rem;
        left: 40%; 
    }
    .img-col .qc-img-composition .qc-img-3 {
        top: 10rem;
        left: 15%; 
    }
}