.footer {
    position: relative;
    background-color: black;
    padding: 5rem 0;
    font-size: 1rem; 
}

.footer_copyright {
    border-top: 1px solid #f7f7f7;
    color: var(--color-grey-light-1);
    /* width: 80%; */
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
.footer_inspire {
    color: var(--color-grey-dark-3);
}