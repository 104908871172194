html{
    font-size: 80%;
}

:root {
    --color-primary: #eb2f64;
    --color-primary-light:#f6d22fd5;
    --color-primary-dark: #e7c013d3;
    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;
    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;
    --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
    --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
    --font-1: 'Cairo', sans-serif;
    --font-2: 'Noto Sans JP', sans-serif;
    --font-3:  "Lato",sans-serif;
    --line: 1rem solid var(--color-grey-dark-1); 
}

.app {
    font-family: var(--font-3);
    font-weight: 200;
    color: #fff;
    font-size: 16px;
    line-height: 1.7;
    padding: 0.5rem; 
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-30%); }
    80% {
        transform: translateX(2%); }
    100% {
        opacity: 1;
        transform: translateX(0); } 
}
  
@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(30%); }
    80% {
        transform: translateX(-2%); }
    100% {
        opacity: 1;
        /* transform: translateX(0); */ } 
}

.heading-primary {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 2rem;
    backface-visibility: hidden;
}
.heading-primary .heading-primary-main {
    display: block;
    letter-spacing: 2px;
    /* font-weight: 400;
    font-size: 1.6rem; */
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out; 
}
.heading-primary .heading-primary-sub {
    display: block;
    letter-spacing: 2px;
    /* font-weight: 300;
    font-size: 1.4rem; */
    animation-name: moveInRight;
    animation-duration: 2s;
    animation-timing-function: ease-out; 
}


.btn, .btn:link, .btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    font-size: 1rem;
    font-weight: 300;
    border: none;
    cursor: pointer;
}
  
.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); 
}
  
.btn:active, .btn:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); 
}
  
.btn-white {
    background-color: #fff;
    color: #777; 
}
  
.btn-yellow {
    margin: 0 auto;
    margin-left: 43%;
    background-color: #e6d706d3;
    color: #fff; 
}
  
.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}
  
.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; 
}
  
.btn-white::after {
    background-color: #fff; 
}
  
.btn-yellow::after {
    background-color: #e6d706d3; 
}
  
.btn-email {
    margin: 5%;
    margin-left: 0; 
}
.text-subheading-1{
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-grey-dark-1);
}
.text-subheading-2{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--color-grey-dark-1);
}
.text-large {
    font-weight: 500;
    font-size: 1.6rem;
}
.text-medium{
    font-weight: 400;
    font-size: 1.4rem;
}
.text-small {
    font-size: 1.2rem;
    font-weight: 700;
}
.text-vsmall{
    font-size: 1rem;
    font-weight: 500;
}
.force-center{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; 
}
.u-center {
    text-align: center; 
}

.u-padding{
    padding: 1rem;
}
  
.u-margin-bottom-large {
    margin-bottom: 8rem; 
}

.u-margin-bottom-medium {
    margin-bottom: 4rem; 
}

.u-margin-top-large {
    margin-top: 8rem; 
}

.u-margin-top-medium {
    margin-top: 4rem; 
}
.u-margin-top-small {
    margin-top: 2rem; 
}

.u-margin-bottom-small {
    margin-bottom: 2rem; 
}

.u-margin-left-small {
    margin-left: 8rem; 
}

.row-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.row-1-2{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.row-3{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1120px) {
    html{
        font-size:62.5%;
    }
    .row-3{
        height: 100%;
        grid-template-columns: 1fr ;
        grid-template-rows: 2fr 1fr 2fr;
        /* display: block */
    }
}
@media (max-width: 895px) {
    html{
        font-size:55%;
    }
}
@media (max-width: 786px) {
    .row-2{
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr 1fr;
        /* display: block */
    }
    .row-1-2{
        grid-template-columns: 1fr ;
        grid-template-rows: 2fr 1fr;
        /* display: block */
    }
    .u-padding{
        padding: 0.5rem;
    }
}
/* @media (max-width: 416px) {
    
} */