.header {
    position: relative;
    height: 95vh;
    /* background-image: linear-gradient(to right, #7ed56fd5, #28b485d3), url(../../assets/Factory.jpg); */
    background-image: linear-gradient(to right, var(--color-primary-light), var(--color-primary-dark)), url(../../assets/Factory.jpg);
    background-size: cover;
    background-position: top;
}

.header .image{
    margin-top:2rem;
    height: 15rem;
}

.logo-box {
    position: absolute;
    top: 2%;
    left: 2%;
    backface-visibility: hidden;
}
.logo-box .logo {
    height: 5rem; 
}

.header .text-box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; 
}
