.nav-links-holder{
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    backface-visibility: hidden;
}

.navbar-holder{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--color-grey-light-1);
    border-bottom: 1px solid var(--color-grey-dark-3);
    padding: 1rem 0 5rem 0;
    z-index: 5;
}

.navbar-holder .logo-box {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}
.navbar-holder .logo-box .logo {
    height: 50px; 
}

@media (max-width: 768px) {
    .navbar-holder .logo-box { 
        left: 8%;
    }
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
.nav-links li {
    margin: 1rem;
}
  
.nav-links a {
    border: 1px solid transparent;
    color: #fff;
    /* font-weight: 300;
    font-size: 1.4rem; */
    text-decoration: none;
    padding: 0.5rem;

    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-grey-light-1) 50%);
    background-size: 400%;
    transition: all .4s;
}
  
.nav-links a:hover {
    background-position: 100%;
    color: var(--color-primary-light);
    transform: translateY(1rem);
}
.non-home-nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
.non-home-nav-links li {
    margin: 1rem;
}
  
.non-home-nav-links a {
    border: 1px solid transparent;
    color: var(--color-grey-dark-1);
    /* font-weight: 300;
    font-size: 1.4rem; */
    text-decoration: none;
    padding: 0.5rem;

    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-primary-light) 50%);
    background-size: 280%;
    transition: all .4s;
}
  
.non-home-nav-links a:hover,
.non-home-nav-links a:active,
.non-home-nav-links a.active {
    background-position: 100%;
    color: var(--color-grey-light-1);
}



.small-nav-item{
    margin: 1rem;
    position: relative;
}
.small-nav-link{
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 1rem 2rem;
    font-family: var(--font-3); 
    color: var(--color-grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-grey-light-1) 50%);
    background-size: 280%;
    transition: all .4s;
}
/* .small-nav-link:hover{
    background-position: 100%;
    color: var(--color-primary-light);
    transform: translateX(1rem);
} */
.small-nav-list a:hover,
.small-nav-list a:active,
.small-nav-list a.active {
    background-position: 100%;
    color: var(--color-primary-light);
    transform: translateX(1rem);
}