.section-factory {
    background-color: #f7f7f7;
    margin-top: 2rem;
    padding: 2rem 0; 
}

.carousel-img-holder{
    height: 100%;
}

.carousel-img-item{
    height: 100%;
}

.carousel-img{
    height: 100%;
    
    /* filter: grayscale(100%); */
    filter: blur(4px);
    -webkit-filter: blur(4px);
    -webkit-filter: grayscale(100%);
    filter: blur(4px) grayscale(100%);

    background-size: cover;
}

.factory-heading {
    color: var(--color-grey-light-3);
    text-transform: uppercase; 
}
  
.factory-paragraph {
    color: var(--color-grey-light-4);
}

.img-dark-overlay {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.factory-home{
    height: calc(100vh - 6rem);
    position: relative;
}

.foreground{
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    /* background-image: linear-gradient(to right, var(--color-grey-dark-2), var(--color-grey-dark-3)), url('../../assets/Plant.JPG');
    background-size: cover;
    background-position: top; */
}

.factory-header{
    height:20%;
}

.factory-header p {
    color:var(--color-primary-dark)
}

.factory-subheading-holder{
    position:'relative';
    height:80%;
}

.factory-subheading-holder > div{
    /* background-color: var(--color-grey-dark-1); */
    padding: 1rem;
    background-color: rgba(0,0,0,.7);
    color: #fff;
}

.carousel-holder{
    height: 100%;
}

/* @media (max-width: 416px) {
    .factory-subheading-holder{
        height:90%;
    }
} */